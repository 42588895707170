import _ from "lodash";
import { Fragment } from "react";
import { useSearchParams } from "react-router";
import ButtonLink from "~/components/button-link";
import DatePickerMenu from "~/components/date-picker-menu";
import Heading from "~/components/heading";
import IntervalMenu from "~/components/interval-menu";
import Link from "~/components/link";
import NavBar from "~/components/nav-bar";
import NavLink from "~/components/nav-link";
import NavWrapper from "~/components/nav-wrapper";
import NumberedTable from "~/components/numbered-table";
import Panel from "~/components/panel";
import UserMenu from "~/components/users/menu";
import type { SalesMetricsQuery } from "~/types/api";
import { useCurrentUser } from "~/utils/auth";
import {
  addDays,
  addMonths,
  dayIsSame,
  endOfMonth,
  endOfWeek,
  formatDate,
  parseDate,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear
} from "~/utils/dates";
import { formatNumber } from "~/utils/formatting";

type IntervalType = "Daily" | "Weekly" | "Monthly" | "Quarterly" | "Annual";

type Props = {
  panel?: boolean;
  defaultInterval?: IntervalType;
  salesMetrics: SalesMetricsQuery["salesMetrics"];
  user?: SalesMetricsQuery["user"];
};
export default function SalesMetricsTable({
  panel,
  defaultInterval = "Daily",
  salesMetrics,
  user
}: Props) {
  const [params] = useSearchParams();
  const currentUser = useCurrentUser();

  const format = (value: number) => {
    return value ? (
      formatNumber(value, { format: "0,0" })
    ) : (
      <span className="text-muted">-</span>
    );
  };

  const dates = (
    date: string,
    interval: IntervalType
  ): { start: Date; end?: Date }[] => {
    switch (interval) {
      case "Daily":
        return [{ start: parseDate(date) }];
      case "Weekly": {
        const first = startOfWeek(date);
        const ranges = Array.from(Array(7).keys());
        return ranges.map((i) => ({ start: addDays(first, i) }));
      }
      case "Monthly": {
        const first = startOfMonth(date);
        const ranges = [{ start: first, end: endOfWeek(first) }];
        let next = addDays(endOfWeek(first), 1);
        while (next.getMonth() === first.getMonth()) {
          ranges.push({ start: next, end: endOfWeek(next) });
          next = addDays(next, 7);
        }
        while (_.last(ranges)!.end.getMonth() !== first.getMonth()) {
          _.last(ranges)!.end = addDays(_.last(ranges)!.end, -1);
        }
        return ranges;
      }
      case "Quarterly": {
        const first = startOfQuarter(date);
        const ranges = [{ start: first, end: endOfMonth(first) }];
        let next = addDays(endOfMonth(first), 1);
        ranges.push({ start: next, end: endOfMonth(next) });
        next = addMonths(next, 1);
        ranges.push({ start: next, end: endOfMonth(next) });
        return ranges;
      }
      case "Annual": {
        const first = startOfYear(date);
        const ranges = [{ start: first, end: endOfMonth(first) }];
        let next = addDays(endOfMonth(first), 1);
        while (next.getFullYear() === first.getFullYear()) {
          ranges.push({ start: next, end: endOfMonth(next) });
          next = addMonths(next, 1);
        }
        return ranges;
      }
    }
  };

  const header = (date: string, interval: IntervalType) => {
    switch (interval) {
      case "Daily":
        return <th className="text-center">Count</th>;
      case "Weekly": {
        const datesForInterval = dates(date, interval);
        return (
          <>
            {datesForInterval.map((d) => (
              <th key={d.start.toISOString()} className="text-center font-bold">
                {formatDate(d.start, { format: "ddd" })}
                <br />
                {formatDate(d.start, { format: "M/D" })}
              </th>
            ))}
            <th className="text-center font-bold">
              <br />
              Total
            </th>
          </>
        );
      }
      case "Monthly": {
        const datesForInterval = dates(date, interval);
        return (
          <>
            {datesForInterval.map((d) => (
              <th key={d.start.toISOString()} className="text-center font-bold">
                {formatDate(d.start, { format: "M/D" })}-
                {formatDate(d.end, { format: "M/D" })}
              </th>
            ))}
            <th className="text-center font-bold">Total</th>
          </>
        );
      }
      case "Quarterly": {
        const datesForInterval = dates(date, interval);
        return (
          <>
            {datesForInterval.map((d) => (
              <th key={d.start.toISOString()} className="text-center font-bold">
                {formatDate(d.start, { format: "MMM YYYY" })}
              </th>
            ))}
            <th className="text-center font-bold">Total</th>
          </>
        );
      }
      case "Annual": {
        return (
          <>
            {[
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec"
            ].map((month) => (
              <th key={month} className="text-center">
                {month}
              </th>
            ))}

            <th className="text-center font-bold">Total</th>
          </>
        );
      }
    }
  };

  const userId = params.get("user") || currentUser.id;
  const date =
    params.get("date") || formatDate(new Date(), { format: "YYYY-MM-DD" });
  const interval = params.get("interval")
    ? (params.get("interval") as IntervalType)
    : defaultInterval;
  const formatted =
    interval === "Annual"
      ? formatDate(date, { format: "YYYY" })
      : interval === "Monthly"
        ? formatDate(date, { format: "MMMM YYYY" })
        : interval === "Weekly"
          ? `Week of ${formatDate(startOfWeek(date))}`
          : formatDate(date);

  const menuItems = (
    <>
      {!panel && (
        <UserMenu
          includeAll
          additionalOptions={[
            ["All M&S Team", "M&S"],
            ["All Sales Team", "Sales"]
          ]}
          value={userId}
          redirect
        />
      )}
      <IntervalMenu value={interval} redirect />
      <DatePickerMenu value={date} redirect />
    </>
  );

  const grouped = _.groupBy(salesMetrics || [], "category");
  const table = (
    <NumberedTable>
      <thead>
        <tr>
          <th />
          <th>Item</th>
          {header(date, interval)}
        </tr>
      </thead>
      <tbody>
        {Object.keys(grouped).map((g) => (
          <Fragment key={g}>
            <tr className="info font-bold">
              <td />
              <td colSpan={99}>{g}</td>
            </tr>
            {_.uniqBy(grouped[g], "name").map((row) => {
              let counts = 0;
              return (
                <tr key={row.name}>
                  <td />
                  <td>
                    {row.link ? (
                      <Link to={row.link}>{row.name}</Link>
                    ) : (
                      row.name
                    )}
                  </td>
                  {dates(date, interval).map((range) => {
                    const match = grouped[g].find(
                      (i) =>
                        i.name === row.name && dayIsSame(range.start, i.date)
                    );
                    if (match && !match.totalOnly) {
                      counts += match.count;
                    }
                    return (
                      <Fragment key={range.start.toISOString()}>
                        <td className="left-border text-center">
                          {format(match?.count || 0)}
                        </td>
                      </Fragment>
                    );
                  })}
                  {interval !== "Daily" && (
                    <td className="left-border text-center">
                      {format(counts)}
                    </td>
                  )}
                </tr>
              );
            })}
          </Fragment>
        ))}
      </tbody>
    </NumberedTable>
  );

  if (panel) {
    return (
      <Panel>
        <Panel.Header
          title="Sales Metrics"
          titleClassName="text-center"
          floatButton
          button={
            <ButtonLink
              to={`/sales/activity-log?user=${userId}`}
              className="show-when-hover-parent"
              small
            >
              View Activity
            </ButtonLink>
          }
        />
        <NavBar
          secondary
          rightMenu={
            <NavLink to={`/sales/activity-log?user=${userId}`}>
              View Details
            </NavLink>
          }
        >
          {menuItems}
        </NavBar>
        {table}
      </Panel>
    );
  }

  return (
    <NavWrapper menuItems={menuItems}>
      <div>
        <Heading
          title={`${interval} Sales Metrics for ${
            userId === "ALL"
              ? "All Staff"
              : userId === "M&S"
                ? "M&S Team"
                : userId === "Sales"
                  ? "Sales Team"
                  : user?.fullname
          }: ${formatted}`}
        />
        {table}
        {userId !== "ALL" && (
          <em>
            Note: All rows related to opportunities and projects are for those
            where {userId === currentUser.id ? "you are" : "this person is"} the
            opportunity owner
          </em>
        )}
      </div>
    </NavWrapper>
  );
}
